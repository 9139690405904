import React, { FC, useContext } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useLayoutEffect } from 'react';
import { Page } from '../ts/interfaces/Generic';

import {
  BookHotelPage,
  ResultHotelPage,
  DetailsHotelPage,
  SuccessHotelPage,
  BookPayment,
} from '@bepro-travel/fe.hotel';
import { MainContext } from '../contexts/MainContext';
import LoginPage from '../pages/Login';
import { GroupRequestPage, MainMOPage, PreviewPage } from '@bepro-travel/fe.shared';
import NotFoundPage from '../pages/NotFoundPage';
import MainPage from '../pages/MainPage';

interface RouterProps {
  isEnigma: boolean;
}

const ResultHotel = () => {
  const { proposals, setProposals } = useContext(MainContext);
  return <ResultHotelPage proposals={proposals} setProposals={setProposals} />;
};
const Router: FC<RouterProps> = ({ isEnigma }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { user } = useContext(MainContext);

  useLayoutEffect(() => {
    const unauthorizedLocations = ['/group'];
    if (!user?.isAuth && !unauthorizedLocations.includes(location.pathname)) {
      navigate('/login');
    }
    window.scrollTo(0, 0);
  }, [location.pathname]); // eslint-disable-line

  const MainP = () => <MainPage isEnigma={isEnigma} />;

  const pages: Page[] = [
    { path: '/', component: MainP },
    { path: '/group', component: GroupRequestPage },
    { path: '/hotels', component: MainP },
    { path: '/hotels/results', component: ResultHotel },
    { path: '/hotel/details', component: DetailsHotelPage },
    { path: '/hotel/book', component: BookHotelPage },
    { path: '/hotel/payment', component: BookPayment },
    { path: '/hotel/success', component: SuccessHotelPage },
    { path: '/login', component: LoginPage },
    { path: '/my-orders', component: MainMOPage },
    { path: '/pages/*', component: MainP },
    { path: '/preview', component: PreviewPage },
  ];

  return (
    <Routes>
      {pages.map(({ component: Component, path }) => {
        return <Route key={path} path={path} Component={Component} />;
      })}
      <Route element={<NotFoundPage />} path="*" />
    </Routes>
  );
};

export default Router;
