import { FC, useEffect, useState } from 'react';
import { getRandomImage, getWorkingHours } from '../services/config';
import { MainHotelPage } from '@bepro-travel/fe.hotel';
import { cdnUrl } from '../constants/generic';
import { WorkingHoursData } from '../ts/interfaces/Generic';
import ContactCenter from '../components/ContactCenter';

interface MainCustomageProps {
  isEnigma: boolean;
}
const MainCustomage: FC<MainCustomageProps> = ({ isEnigma }) => {
  const [workingHours, setWorkingHours] = useState<WorkingHoursData>();
  useEffect(() => {
    (async () => {
      if (!isEnigma) {
        const response = await getRandomImage();
        const elem = document.querySelector('.main-page.hotels') as HTMLElement;
        elem.style.backgroundImage = `url(${cdnUrl + response?.imageUrl})`;
      }

      const data = await getWorkingHours();
      try {
        const workingHours = JSON.parse(data.officeInformation);
        setWorkingHours(workingHours);
      } catch (e) {}
    })();
  }, []); // eslint-disable-line

  return (
    <div>
      {workingHours && <ContactCenter workingHours={workingHours} />}
      <MainHotelPage />
    </div>
  );
};

export default MainCustomage;
